import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="HoOme" />
    <div style={{ paddingTop: "120px" }}>contact here</div>
  </Layout>
)

export default ContactPage
